"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const blockstack = require('blockstack');
class default_1 {
    constructor() {
        if (blockstack.isUserSignedIn()) {
            const userData = blockstack.loadUserData();
            this.username = userData.username;
            this.publicKey = blockstack.getPublicKeyFromPrivate(userData.appPrivateKey);
            this.privateKey = userData.appPrivateKey;
        }
    }
    static getBlockstackLocalStorage() {
        return {
            BLOCKSTACK: localStorage.getItem('blockstack'),
            BLOCKSTACK_GAIA_HUB_CONFIG: localStorage.getItem('blockstack-gaia-hub-config'),
            BLOCKSTACK_TRANSIT_PRIVATE_KEY: localStorage.getItem('blockstack-transit-private-key')
        };
    }
}
exports.default = default_1;
