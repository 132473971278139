"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings_1 = require("./settings");
const blockstack = require('blockstack');
const store_1 = require("./store");
const router_1 = require("./components/router");
async function routeLoggedIn() {
    const savedRoute = parseInt(sessionStorage.route, 10);
    let savedRouteParams = sessionStorage.routeParams;
    if (savedRouteParams)
        savedRouteParams = JSON.parse(savedRouteParams);
    const route = (router_1.Route[savedRoute]) ? savedRoute : router_1.Route.FormsList;
    await settings_1.loadSettings();
    store_1.default.setRouteAction(route, savedRouteParams || {});
}
function main() {
    // hax
    if (blockstack.isUserSignedIn()) {
        routeLoggedIn();
    }
    else if (blockstack.isSignInPending()) {
        blockstack.handlePendingSignIn()
            .then(routeLoggedIn)
            .catch(console.warn);
    }
    else {
        store_1.default.setRouteAction(router_1.Route.Login);
    }
}
if (sessionStorage.debug) {
    window.blockstack = blockstack;
}
// side effects
main();
