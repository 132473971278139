"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const store_1 = require("../store");
const login_1 = require("./login/login");
const view_form_1 = require("./view-form/view-form");
const list_forms_1 = require("./list-forms/list-forms");
const builder_1 = require("./builder/builder");
const view_submissions_1 = require("./view-submissions/view-submissions");
const settings_1 = require("./settings/settings");
var Route;
(function (Route) {
    Route[Route["Login"] = 1] = "Login";
    Route[Route["FormView"] = 2] = "FormView";
    Route[Route["FormsList"] = 3] = "FormsList";
    Route[Route["Build"] = 4] = "Build";
    Route[Route["SubmissionsView"] = 5] = "SubmissionsView";
    Route[Route["SettingsView"] = 6] = "SettingsView";
})(Route = exports.Route || (exports.Route = {}));
const map = new Map([
    [Route.Login, `<login></login>`],
    [Route.FormView, `<forms-view></forms-view>`],
    [Route.FormsList, `<forms-list></forms-list>`],
    [Route.Build, `<build-form></build-form>`],
    [Route.SubmissionsView, `<submissions-view></submissions-view>`],
    [Route.SettingsView, `<settings-view></settings-view>`]
]);
const viewInitMap = new Map([
    [Route.Login, login_1.update],
    [Route.FormView, view_form_1.update],
    [Route.FormsList, list_forms_1.init],
    [Route.Build, builder_1.init],
    [Route.SubmissionsView, view_submissions_1.update],
    [Route.SettingsView, settings_1.update],
]);
let lastRoute = -1;
function update() {
    const el = document.querySelector('router');
    console.assert(!!el);
    let currentRoute = store_1.default.store.route;
    if (lastRoute !== currentRoute) {
        const tpl = map.get(currentRoute) || `View ${Route[currentRoute]} doesn't exist`;
        el.innerHTML = tpl;
        localStorage.debug && console.debug(lastRoute, '=>', currentRoute);
        const initFunc = viewInitMap.get(currentRoute);
        initFunc();
        lastRoute = currentRoute;
    }
}
exports.update = update;
function persist() {
    sessionStorage.route = store_1.default.store.route;
    sessionStorage.routeParams = JSON.stringify(store_1.default.store.routeParams);
}
exports.persist = persist;
