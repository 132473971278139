"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_extended_1 = require("../../../node_modules/lit-html/lib/lit-extended");
const dappform_forms_api_1 = require("dappform-forms-api");
const store_1 = require("../../store");
const router_1 = require("../router");
const uuid_1 = require("uuid");
const blockstackUtils_1 = require("../../util/blockstackUtils");
const settings = require("../../settings");
const questions = [];
function init() {
    questions.length = 0;
    update();
    const templateFrom = store_1.default.store.routeParams.formToCopy;
    if (templateFrom) {
        applyTemplate(templateFrom);
    }
}
exports.init = init;
async function applyTemplate(formUuid) {
    const form = await dappform_forms_api_1.getForm(formUuid);
    document.querySelector('[name=form-name]').value = form.name || '';
    document.querySelector('[name=intro-text]').value = form.introText || '';
    document.querySelector('[name=confirmation-text]').value = form.confirmationText || '';
    document.querySelector('[name=primary-color]').value = form.primaryColor || '';
    document.querySelector('[name=intro-background-url]').value = form.introImageURL || '';
    form.questions.forEach(q => {
        questions.push(q);
    });
    update();
}
function update() {
    const el = document.querySelector(`build-form`);
    const save = async (evt) => {
        evt.target.disabled = true;
        const newForm = collectForm();
        await dappform_forms_api_1.createForm(newForm);
        await wait(0.5); // hax: give blockstack a little time to refresh forms.json file (seems to be needed even though we await updating forms.json)
        store_1.default.setRouteAction(router_1.Route.FormsList);
    };
    const tpl = lit_extended_1.html `
<h3>Build</h3>

<form class="grid-x grid-margin-y grid-margin-x">
  <div class="cell small-6">
    <label>
        Human-readable name
        <input type="text" name="form-name" required>
    </label>
  </div>
  <div class="cell small-6">
    <label>
        Confirmation text
        <input type="text" name="confirmation-text">
    </label>
  </div>
  <div class="cell small-12">
    <label>
        Intro text
        <textarea type="text" name="intro-text"></textarea>
    </label>
  </div> 
  <div class="cell small-6">
    <label>
        Brand/primary colour
        <input type="color" name="primary-color" value="#00bfff">
    </label>
  </div>
  <div class="cell small-6">
    <label>
        Background image for the intro screen
        <input type="url" name="intro-background-url">
    </label>
  </div>
  
 
  <div class="cell small-12">
    ${questions.map(q => lit_extended_1.html `
    <div class="grid-x grid-margin-x grid-margin-y question-item">
      <div class="cell auto">
        <label>Question label
          <input type='text' name="${q.name}" value="${q.label}" placeholder="Question label">
        </label>
      </div>
      <div class="cell small-3">
        <label>Type
          <select name="${q.name}-q-type">
              <option selected?="${q.type === 'text'}">text</option>
              <option selected?="${q.type === 'email'}">email</option>
              <option selected?="${q.type === 'number'}">number</option>
              <option selected?="${q.type === 'datetime-local'}">datetime-local</option>
              <option selected?="${q.type === 'tel'}">tel</option>
              <option selected?="${q.type === 'url'}">url</option>
              <option selected?="${q.type === 'file'}" value="file">file attachment</option>
          </select>
        </label>
      </div>
      <div class="cell shrink align-self-bottom">
        <button data-uuid$="${q.uuid}" on-click="${(evt) => removeField(evt)}" class="hollow button warning expanded" type="button">Remove</button>
      </div>
    </div>
  `)}
    
  </div>

  <div class="cell small-12">
    <div class="grid-x">
      <div class="cell auto">
          <button on-click="${() => { addField(questions); update(); }}" class="hollow button" type="button">Add question</button>
      </div>
      <div class="cell shrink">
          <button on-click="${(evt) => save(evt)}" class="hollow button primary" type="button">Save</button>
      </div>
    </div>
  </div>
</form>
`;
    lit_extended_1.render(tpl, el);
}
exports.update = update;
function collectForm() {
    // basics
    const authorPubKey = new blockstackUtils_1.default().publicKey;
    const newFrom = {
        authorPubKey,
        uuid: uuid_1.v4(),
        created: new Date(),
        modified: new Date(),
        questions: [],
        name: document.querySelector('[name=form-name]').value,
        introText: document.querySelector('[name=intro-text]').value,
        confirmationText: document.querySelector('[name=confirmation-text]').value,
        primaryColor: document.querySelector('[name=primary-color]').value,
        introImageURL: document.querySelector('[name=intro-background-url]').value,
        submissionsUrl: settings.getValue('submissionTaskUrl'),
    };
    // questions
    newFrom.questions = Array.from(document.querySelectorAll('.question-item'))
        .map((el) => {
        const [label] = Array.from(el.querySelectorAll('input')).map(el => el.value);
        const [type] = Array.from(el.querySelectorAll('select')).map(el => el.value);
        return {
            uuid: uuid_1.v4(),
            label,
            name: label,
            type,
            created: new Date(),
            modified: new Date(),
        };
    });
    // question-item
    return newFrom;
}
function addField(questions) {
    const q = {
        uuid: uuid_1.v4()
    };
    questions.push(q);
}
function removeField(event) {
    event.target.parentElement.parentElement.classList.add('hide');
    event.target.parentElement.parentElement.classList.remove('question-item');
}
function wait(sec) {
    return new Promise(resolve => setTimeout(() => resolve(), sec * 1000));
}
