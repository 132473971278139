"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const write_1 = require("./util/write");
const store_1 = require("./store");
// set wether readonly is true or false
exports.settingsSchema = {
    email: false,
    cronSchedule: false,
    webtaskId: false,
    webtaskToken: false,
    postmarkToken: false,
    postmarkFrom: false,
    submissionTaskUrl: true,
    hostingTaskUrl: true,
    statsTaskUrl: true,
    tasksViewCounter: true,
    webhookUrl: false,
};
function getValue(key) {
    return store_1.default.store.settings[key];
}
exports.getValue = getValue;
function setValue(key, value) {
    store_1.default.store.settings[key] = value;
}
exports.setValue = setValue;
async function loadSettings() {
    const s = await write_1.getFile('settings.json');
    if (typeof s === "object") {
        console.log('Settings from storage: ', s);
        store_1.default.setSettingsAction(Object.assign({}, s, store_1.default.store.settings));
        store_1.default.setSettingsLoadedAction(true);
    }
    else {
        console.warn("Failed loading settings from storage");
    }
}
exports.loadSettings = loadSettings;
async function saveSettings() {
    await write_1.putFile('settings.json', store_1.default.store.settings);
}
exports.saveSettings = saveSettings;
