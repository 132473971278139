"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const blockstackUtils_1 = require("../../util/blockstackUtils");
const lit_extended_1 = require("../../../node_modules/lit-html/lib/lit-extended");
const settings = require("../../settings");
const webtask_1 = require("../../util/webtask");
const settings_1 = require("../../settings");
const store_1 = require("../../store");
function sendReports() {
    fetch(settings.getValue('statsTaskUrl')).then(console.log, console.warn).catch(console.warn);
}
exports.codeBases = {
    hostingTaskUrl: 'https://raw.githubusercontent.com/FoundersAS/dappform-tasks-form-hosting/master/package.json',
    submissionTaskUrl: 'https://raw.githubusercontent.com/FoundersAS/dappform-tasks-submissions/master/package.json',
    statsTaskUrl: 'https://raw.githubusercontent.com/FoundersAS/dappform-tasks-stats/master/package.json',
    tasksViewCounter: 'https://raw.githubusercontent.com/FoundersAS/dappform-tasks-view-counter/master/package.json',
};
async function deployTasks() {
    webtask_1.createWebTaskTask('dappform-tasks-host', "https://raw.githubusercontent.com/FoundersAS/dappform-tasks-form-hosting/master/index.js", "https://raw.githubusercontent.com/FoundersAS/dappform-tasks-form-hosting/master/package.json", {})
        .then(result => settings.setValue('hostingTaskUrl', result.webtask_url)).catch(console.error)
        .then(() => Save.scheduleSave());
    webtask_1.createWebTaskTask('dappform-tasks-host', "https://raw.githubusercontent.com/FoundersAS/dappform-tasks-form-hosting/master/index.js", "https://raw.githubusercontent.com/FoundersAS/dappform-tasks-form-hosting/master/package.json", {}).then(result => settings.setValue('hostingTaskUrl', result.webtask_url)).catch(console.error)
        .then(() => Save.scheduleSave());
    webtask_1.createWebTaskTask('dappform-tasks-view-counter', "https://raw.githubusercontent.com/FoundersAS/dappform-tasks-view-counter/master/index.js", "https://raw.githubusercontent.com/FoundersAS/dappform-tasks-view-counter/master/package.json", Object.assign({}, blockstackUtils_1.default.getBlockstackLocalStorage())).then(result => settings.setValue('tasksViewCounter', result.webtask_url)).catch(console.error)
        .then(() => Save.scheduleSave());
    webtask_1.createWebTaskTask('dappform-tasks-submission', 'https://raw.githubusercontent.com/FoundersAS/dappform-tasks-submissions/master/index.js', 'https://raw.githubusercontent.com/FoundersAS/dappform-tasks-submissions/master/package.json', Object.assign({}, blockstackUtils_1.default.getBlockstackLocalStorage(), { BLOCKSTACK_APP_PRIVATE_KEY: new blockstackUtils_1.default().privateKey }))
        .then(result => settings.setValue('submissionTaskUrl', result.webtask_url)).catch(console.error)
        .then(() => Save.scheduleSave());
    webtask_1.createWebTaskTask('dappform-tasks-stats', 'https://raw.githubusercontent.com/FoundersAS/dappform-tasks-stats/master/index.js', 'https://raw.githubusercontent.com/FoundersAS/dappform-tasks-stats/master/package.json', Object.assign({}, blockstackUtils_1.default.getBlockstackLocalStorage(), { POSTMARK_TOKEN: settings.getValue('postmarkToken'), POSTMARK_FROM: settings.getValue('postmarkFrom'), POSTMARK_TO: settings.getValue('email') })).then(result => settings.setValue('statsTaskUrl', result.webtask_url)).catch(console.error)
        .then(() => Save.scheduleSave());
    webtask_1.createCronSchedule('dappform-tasks-stats', settings.getValue('cronSchedule'))
        .catch(console.error);
}
class Save {
    static async scheduleSave(delay = 1) {
        if (!Save.scheduled) {
            Save.scheduled = true;
            await wait(delay);
            await Promise.all([update(), settings.saveSettings()]);
            Save.scheduled = false;
        }
    }
}
function wait(sec) {
    return new Promise(resolve => setTimeout(() => resolve(), sec * 1000));
}
async function saveUserDefinedSettings() {
    Object.entries(settings.settingsSchema)
        .filter(([key, readonly]) => !readonly)
        .forEach(([key]) => {
        settings.setValue(key, document.querySelector(`[name=${key}]`).value);
    });
    await settings.saveSettings();
    update();
}
async function update() {
    const el = document.querySelector('settings-view');
    let helpText;
    if (!store_1.default.store.settingsLoaded) {
        await settings_1.loadSettings();
    }
    const rows = Object.entries(settings.settingsSchema).map(([key, readonly]) => {
        let deployedVersionPromise;
        let githubVersionPromise;
        let matchPromise;
        const lookupVersionForKey = readonly; // it just so happens that the read-only keys are all web tasks
        if (settings.getValue(key) && lookupVersionForKey) {
            const url = new URL(settings.getValue(key));
            url.pathname = `${url.pathname}/version`;
            deployedVersionPromise = fetch(url.toString())
                .then(res => (res.status < 300) ? res.text() : Promise.reject("Status not 200 for " + res.url))
                .catch(reason => console.warn(`Getting deployed version failed ${url.toString()}. `, reason));
            githubVersionPromise = fetch(exports.codeBases[key])
                .then(res => (res.status < 300) ? res.json() : Promise.reject("Status not 200 for " + exports.codeBases[key]))
                .then(packageJson => (typeof packageJson === "object") ? `${packageJson.version}` : "0.0.0")
                .catch(reason => console.warn("Getting latest version failed. ", reason));
            matchPromise = Promise.all([deployedVersionPromise, githubVersionPromise])
                .then(([deployed, githubVer]) => {
                const match = (deployed === githubVer) ? lit_extended_1.html `<span class="label secondary">Up to date</span>` : lit_extended_1.html `<span class="label success">New code available!</span>`;
                return lit_extended_1.html `
                ${match}
                Deployed ${deployed} --
                Latest ${githubVer}
              `;
            });
            // matchPromise.then(m => console.log(m))
        }
        const value = settings.getValue(key);
        const type = key.includes("email") ? "email" : "text";
        const required = false;
        return {
            type,
            readonly,
            key,
            value,
            helpText,
            matchPromise,
            required,
        };
    });
    const tpl = lit_extended_1.html `
    <h3>Settings</h3>

    <div class="grid-x grid-margin-y">
      <div class="cell medium-12">
        <button class="button" on-click="${saveUserDefinedSettings}">Save</button>
        <button class="button success" on-click="${deployTasks}">Deploy Tasks</button>
        <button class="button success" on-click="${sendReports}">Send Reports</button>
      </div>
      
      <form class="grid-x grid-margin-x grid-margin-y">
        ${rows.map((vm) => lit_extended_1.html `            
          <div class="cell medium-6">
            <label>
              ${vm.key}
              <input type="${vm.type}" name="${vm.key}" value="${vm.value}" readonly?=${vm.readonly} required?=${vm.readonly}>
            </label>
            <p class="help-text">
                ${vm.helpText}               
                <span>${vm.matchPromise}</span>
            </p>
          </div>
        `)}
      </form>
    </div>
  `;
    lit_extended_1.render(tpl, el);
}
exports.update = update;
