"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings = require("../settings");
function getTaskUrl(taskName) {
    return `https://sandbox.auth0-extend.com/api/webtask/${settings.getValue('webtaskId')}/${taskName}?key=${settings.getValue('webtaskToken')}`;
}
function getCronUrl(taskName) {
    return `https://sandbox.auth0-extend.com/api/cron/${settings.getValue('webtaskId')}/${taskName}?key=${settings.getValue('webtaskToken')}`;
}
async function createWebTaskTask(taskName, taskCodeUrl, taskPackageUrl, taskSecrets = {}) {
    let res;
    res = await fetch(taskPackageUrl);
    const packageJson = await res.json();
    taskSecrets.version = packageJson.version;
    console.debug(`Deploying ${taskName} v${packageJson.version}`);
    res = await fetch(getTaskUrl(taskName), {
        method: 'PUT',
        body: JSON.stringify({
            url: taskCodeUrl,
            secrets: taskSecrets,
            meta: {
                'wt-node-dependencies': JSON.stringify(packageJson.dependencies).replace(/\^/g, '') // remove ^ because webtask doesn't support it
            }
        })
    });
    const deployed = await res.json();
    if (res.status > 299) {
        throw deployed; // error
    }
    return deployed;
}
exports.createWebTaskTask = createWebTaskTask;
async function createCronSchedule(taskName, schedule) {
    return (await fetch(getCronUrl(taskName), {
        method: 'PUT',
        body: JSON.stringify({
            schedule
        })
    }).then((res) => res.json()));
}
exports.createCronSchedule = createCronSchedule;
