"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const blockstack = require('blockstack');
const lit_extended_1 = require("../../../node_modules/lit-html/lib/lit-extended");
function update() {
    const el = document.querySelector('login');
    const login = (evt) => {
        evt.target.disabled = true;
        blockStackSignin();
    };
    const tpl = lit_extended_1.html `
  <h1>Login</h1>
  <button on-click="${(evt) => login(evt)}" type="button" class="login-button button large">Blockstack</button>
  `;
    lit_extended_1.render(tpl, el);
}
exports.update = update;
function blockStackSignin() {
    blockstack.redirectToSignIn(location.href, location.origin + "/manifest.json", [
        'store_write',
        'publish_data',
    ]);
}
exports.blockStackSignin = blockStackSignin;
function blockstackSignout() {
    blockstack.signUserOut(location.origin);
}
exports.blockstackSignout = blockstackSignout;
