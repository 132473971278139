"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const router_1 = require("./components/router");
const nav_1 = require("./components/nav/nav");
// interface ReadOnlyState extends DefaultState {
//   forms: ReadonlyList<Form[]>
// }
// a class for holding
class Store {
    static get store() {
        return this._store;
    }
    static callReducers(action) {
        const reducers = Store.reducers.get(action);
        reducers.forEach(reducer => reducer(this.store));
    }
    // Actions
    static setListViewAction(value) {
        this._store.listView.length = 0;
        for (let f of value) {
            this._store.listView.push(f);
        }
        Store.callReducers(Store.setListViewAction);
    }
    static setRouteAction(value, routeParams = {}) {
        this._store.route = value;
        this._store.routeParams = routeParams;
        Store.callReducers(Store.setRouteAction);
    }
    static setSettingsAction(settings) {
        this._store.settings = settings;
        Store.callReducers(Store.setSettingsAction);
    }
    static setSettingsLoadedAction(isLoaded) {
        this._store.settingsLoaded = isLoaded;
        Store.callReducers(Store.setSettingsLoadedAction);
    }
}
Store.reducers = new Map();
Store._store = {
    route: router_1.Route.Login,
    routeParams: {},
    settings: {},
    settingsLoaded: false,
    listView: [],
};
exports.default = Store;
// glue together actions and reducers
Store.reducers.set(Store.setRouteAction, new Set([
    router_1.update,
    nav_1.update,
    router_1.persist,
]));
Store.reducers.set(Store.setSettingsAction, new Set([]));
Store.reducers.set(Store.setListViewAction, new Set([]));
Store.reducers.set(Store.setSettingsLoadedAction, new Set([]));
