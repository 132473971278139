"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_extended_1 = require("../../../node_modules/lit-html/lib/lit-extended");
const dappform_forms_api_1 = require("dappform-forms-api");
const router_1 = require("../router");
const store_1 = require("../../store");
const write_1 = require("../../util/write");
async function init() {
    const forms = await dappform_forms_api_1.getForms();
    // first render forms
    const rows = forms.filter(form => form.created && form.uuid && form.name)
        .map(form => {
        form.created = new Date(form.created);
        form.modified = new Date(form.modified);
        return form;
    })
        .map(form => (Object.assign({}, form)));
    Promise.all(rows.map(form => Promise.all([dappform_forms_api_1.getFormSubmissions(form.uuid), write_1.getFile(`views/${form.uuid}.json`), form])))
        .then(maps => {
        const updatedRows = maps
            .map(([map, viewsObj, row]) => [map, viewsObj || { numViews: 0, }, row])
            .map(([map, viewsObj, row]) => {
            row.submissions = Object.values(map).length;
            row.views = viewsObj.numViews;
            row.replyRatio = (row.views > 0) ? Math.round((row.submissions / row.views) * 10 ** 2) : 0;
            return row;
        });
        store_1.default.setListViewAction(updatedRows);
        update();
    })
        .catch(err => console.error(err));
    store_1.default.setListViewAction(rows);
    update();
}
exports.init = init;
function update() {
    const { listView } = store_1.default.store;
    const tpl = lit_extended_1.html `
  <h3>Your forms (${listView.length})</h3>
  
  <!--headers-->
  <div class="grid-x grid-margin-y">
      <div class="cell small-4">
          <small>Name</small>
      </div>
      <div class="cell small-1">
          <small>Views</small>
      </div>
      <div class="cell small-2">
          <small>Submissions</small>
      </div>
      <div class="cell small-1">
          <small>Ratio</small>
      </div>
      <div class="cell small-2">
          <small>Created</small>
      </div>
      <div class="cell small-2 text-right">
          <small>Actions</small>
      </div>
  </div>
  <!--rows-->
  ${listView
        .sort((a, b) => a.created.getTime() - b.created.getTime())
        .map(form => lit_extended_1.html `
    <div class="grid-x grid-margin-y">
      <div class="cell medium-4 small-12" style="overflow: hidden;white-space: nowrap">
          ${form.name}
      </div>
      <div class="cell small-1 small-offset-4 hide-for-medium">      
          ${form.views}
      </div>
        <div class="cell small-1 hide-for-small-only">      
            ${form.views}
        </div>
      <div class="cell small-2">
          ${form.submissions}
      </div>
      <div class="cell small-1">
          ${form.replyRatio}%
      </div>
      <div class="cell small-2">
          ${formatDate(form.created)}
      </div>
      <div class="cell small-2 text-right" style="white-space: nowrap">
          <button class="clear button link small" on-click="${() => exportCsv(form.uuid)}" disabled?="${form.submissions === 0}">CSV</button>
          <button class="clear button link small" on-click="${() => store_1.default.setRouteAction(router_1.Route.Build, { formToCopy: form.uuid })}">Copy</button>
          <button class="clear button link small" on-click="${() => store_1.default.setRouteAction(router_1.Route.FormView, { formId: form.uuid })}">View</button>
      </div>
    </div>
    `)}
  `;
    const el = document.querySelector('forms-list');
    lit_extended_1.render(tpl, el);
}
exports.update = update;
async function exportCsv(uuid) {
    const [form, submissionsMap] = await Promise.all([
        dappform_forms_api_1.getForm(uuid),
        dappform_forms_api_1.getFormSubmissions(uuid),
    ]);
    const questions = form.questions;
    const submissions = Object.values(submissionsMap);
    console.assert(submissions.length > 0, 'should have submissions');
    const comma = ","; // lol
    const newLine = '\n';
    const enclose = '"';
    const headers = questions.map(q => q.label)
        .map(val => enclose + val.replace(/[^a-z0-9 ]/ig, '').toLowerCase() + enclose)
        .join(comma);
    const values = submissions
        .map(s => s.answers
        .map(ans => ans.value + "") // toString everything
        .map(ans => (ans.indexOf('http') === 0 && ans.includes('gaia')) ? ans.substr(ans.lastIndexOf(".")) + " file" : ans) // detect file upload link
        .map(val => val.replace(new RegExp(newLine, 'g'), ' ')) // escape new lines
        .map(val => enclose + val.replace(new RegExp(enclose, 'g'), '\\' + enclose) + enclose) // enclose values
        .join(comma))
        .join(newLine);
    const file = new Blob([headers + newLine + values], { type: 'text/csv' });
    // const file = new File([headers + values], 'export.csv')
    const url = URL.createObjectURL(file);
    location.href = url;
}
function formatDate(date) {
    const d = new Date(date).toJSON();
    return d.substr(0, d.indexOf("T"));
}
