"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_extended_1 = require("../../../node_modules/lit-html/lib/lit-extended");
const store_1 = require("../../store");
const router_1 = require("../router");
const dappform_forms_api_1 = require("dappform-forms-api");
const write_1 = require("../../util/write");
const FileSaver = require("file-saver");
const extMimeMap = new Map([
    ['txt', 'text/plain'],
    ['csv', 'text/csv'],
    ['jpeg', 'image/jpeg'],
    ['jpg', 'image/jpeg'],
    ['png', 'image/png'],
    ['js', 'application/javascript'],
    ['xml', 'application/xml'],
    ['xls', 'application/vnd.ms-excel'],
]);
async function getBlob(url) {
    const ext = url.substr(url.lastIndexOf(".") + 1);
    const path = url.substr(url.indexOf('files/'));
    let buffer = await write_1.getAnyFile(path);
    const blob = new Blob([buffer], { type: extMimeMap.get(ext) });
    return { blob, ext };
}
async function download(fileLink) {
    const { blob, ext } = await getBlob(fileLink);
    FileSaver.saveAs(blob, `attachment.${ext}`);
}
async function update() {
    const el = document.querySelector('submissions-view');
    const uuid = store_1.default.store.routeParams.formId;
    const form = await dappform_forms_api_1.getForm(uuid);
    const submissions = await dappform_forms_api_1.getFormSubmissions(uuid);
    const columns = ['uuid', ...form.questions.map(q => q.label)];
    const tableHead = columns.map(c => lit_extended_1.html `<th>${c}</th>`);
    const tableRows = Object.keys(submissions)
        .map(uuid => { return { uuid, answers: submissions[uuid].answers }; })
        .map(d => {
        return lit_extended_1.html `<tr><td>${uuid}</td>${d.answers.map(a => {
            if (typeof a.value === 'string' && a.value.indexOf('http') === 0 && a.value.includes('gaia')) {
                return lit_extended_1.html `<td><button class="button link clear small" on-click="${() => download(a.value)}">Download</button></td>`;
            }
            return lit_extended_1.html `<td>${a.value}</td>`;
        })}</tr>`;
    });
    const table = lit_extended_1.html `
  <table>
    <thead>
      <tr>
        ${tableHead}
      </tr>
    </thead>
    <tbody>
      ${tableRows}
    </tbody>
  </table>
  `;
    const tpl = lit_extended_1.html `
    <h3>Form Submissions</h3>
    <p><small><button class="clear button link" on-click="${() => store_1.default.setRouteAction(router_1.Route.FormView, { formId: uuid })}">(uuid: ${uuid})</button></small></p>
    <div class="grid-x grid-margin-x">
      <div class="cell medium-12">
      ${table}
      </div>
    </div>
  `;
    lit_extended_1.render(tpl, el);
}
exports.update = update;
