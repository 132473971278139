"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const router_1 = require("../router");
const store_1 = require("../../store");
const login_1 = require("../login/login");
const lit_extended_1 = require("../../../node_modules/lit-html/lib/lit-extended");
const blockstack = require('blockstack');
function update() {
    const nav = document.querySelector(`nav`);
    const route = store_1.default.store.route;
    const anon = lit_extended_1.html `
  <div class="cell auto"></div>
  <div class="cell shrink">
      <h5>Made with dapp form</h5>
  </div>
  `;
    const normal = lit_extended_1.html `
  <div class="cell auto nav-item-list">
    <button class="hollow button" on-click="${() => store_1.default.setRouteAction(router_1.Route.FormsList)}">List forms</button>
    <button class="hollow button" on-click="${() => store_1.default.setRouteAction(router_1.Route.Build)}">Build form</button>
    <button class="hollow button" on-click="${() => store_1.default.setRouteAction(router_1.Route.SettingsView)}">Settings</button>
  </div>
  <div class="cell shrink">
    <button class="hollow button secondary button-signout" on-click="${() => login_1.blockstackSignout()}">Sign out</button>
  </div>
  `;
    const tpl = (location.toString().includes('form-id') || !blockstack.isUserSignedIn()) ? anon : normal;
    lit_extended_1.render(tpl, nav);
}
exports.update = update;
