"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_extended_1 = require("../../../node_modules/lit-html/lib/lit-extended");
const store_1 = require("../../store");
const router_1 = require("../router");
const dappform_forms_api_1 = require("dappform-forms-api");
const blockstackUtils_1 = require("../../util/blockstackUtils");
const weekly_stats_1 = require("./weekly-stats");
const settings_1 = require("../../settings");
async function update() {
    const el = document.querySelector('forms-view');
    const uuid = store_1.default.store.routeParams.formId;
    const form = await dappform_forms_api_1.getForm(uuid);
    console.debug("Viewing", form);
    const username = new blockstackUtils_1.default().username;
    const appOrigin = (sessionStorage.hostUrl) ? sessionStorage.hostUrl : settings_1.getValue('hostingTaskUrl');
    const publicFormURL = new URL(appOrigin);
    publicFormURL.searchParams.append(`author`, username);
    publicFormURL.searchParams.append(`form-id`, uuid);
    publicFormURL.searchParams.append(`origin`, encodeURIComponent(location.origin));
    const submissions = await dappform_forms_api_1.getFormSubmissions(uuid);
    const { lastWeek, total } = weekly_stats_1.weeklyStats(Object.values(submissions));
    const copy = () => {
        const el = document.querySelector('[name="copy-url"]');
        el.select();
        document.execCommand('copy');
        el.blur();
    };
    const tpl = lit_extended_1.html `
  <h3>Form dashboard <em>${form.name}</em></h3>
  <p><small>(uuid: ${uuid})</small>

  <div class="grid-x grid-margin-x">
    <div class="cell medium-6">
    <h4>Distribution</h4>
    <form class="grid-x grid-margin-y">     
      <div class="cell small-12">
        <div class="input-group">        
          <input class="input-group-field" readonly type="url" name="copy-url" value="${publicFormURL.toString()}">
          <div class="input-group-button">
            <button type="button" class="button" on-click="${() => copy()}">Copy</button>
          </div>
        </div>
      </div>
    </form>

        <p>
            <a href="${publicFormURL}" target="_blank" class="button large">Open</a>
            <a href="https://ipfs.io/ipfs/QmccqhJg5wm5kNjAP4k4HrYxoqaXUGNuotDUqfvYBx8jrR/qr#${publicFormURL.toString()}" target="_blank" class="button secondary large">QR code</a>
            <button on-click="${(evt) => dappform_forms_api_1.deleteForm(uuid)}" class="alert button large">Delete</button>
        </p>
    </div>

    <div class="cell medium-6">
      <h4>Responses</h4>

      <p><button class="clear button link" on-click="${() => store_1.default.setRouteAction(router_1.Route.SubmissionsView, { formId: uuid })}">View Submissions</button>

      <p>${lastWeek} submissions last week. Total ${total}.</p>
    </div>

  </div>
  `;
    lit_extended_1.render(tpl, el);
}
exports.update = update;
function handleAsyncButton(evt, promise) {
    const el = (evt.target);
    el.disabled = true;
    promise.then(() => el.disabled = false);
}
